import { useEffect } from "react"
import { useAuth } from "lib/auth"
import { useCart } from "lib/cart"
import { useModal } from "lib/modal"
import { useTranslation } from "lib/i18n/translation"
import Router from "next/router"

import { PlayerLauncher } from "components/common"
import langFromQuery from "lib/i18n/lang-from-query"

import { List } from "./header/lists-of"

const CONNEXION = "connexion"
const CREATE_ACCOUNT = "create-account"
const FORGOT_PASSWORD = "forgot-password"
const OFFER_CINEPASS = "offer-cinepass"
const OFFER_SUBSCRIPTION = "offer-subscription"
const OUR_OFFERS = "ways-to-watch"
const LISTS = "lists"
const OFFLINE_PLAYER = "desktop-player"

const QueryMatcher = ({ match }) => {
	const { showLogin, showSignup, showForgotPassword } = useAuth()
	const { addSVODToCart, addCinepassToCart } = useCart()
	const modal = useModal()
	const { c } = useTranslation("common")

	const actions = [
		{
			action: CONNEXION,
			command: () => showLogin()
		},
		{
			action: CREATE_ACCOUNT,
			command: () => showSignup()
		},
		{
			action: FORGOT_PASSWORD,
			command: (email) => showForgotPassword(email)
		},
		{
			action: OFFER_CINEPASS,
			command: () => addCinepassToCart({ offer: true })
		},
		{
			action: OFFER_SUBSCRIPTION,
			command: () => addSVODToCart()
		},
		{
			action: OUR_OFFERS,
			command: () => {
				const language = langFromQuery(Router.query.lang)

				switch (language) {
					case "de":
					case "at":
						Router.push(`${language}/unsere-angebote`)
						break

					case "en":
						Router.push(`${language}/ways-to-watch`)
						break

					default:
						Router.push(`${language}/nos-offres`)
						break
				}

			}
		},
		{
			action: LISTS,
			command: () => modal.show({
				id: "directors",
				children: <List c={c} hide={modal.hide} modal={modal} />,
				className: 'red'
			})
		},
		{
			action: OFFLINE_PLAYER,
			command: () => modal.show({
				id: "player-launcher",
				children: <PlayerLauncher />,
				className: "slide top short"
			})
		}
	]

	useEffect(() => {
		if (match) {
			const command = actions.find(a => a.action == match.action).command
			command(match.value)
		}
	}, [])

	return null
}

const matches = [
	{
		match: ["se-connecter", "connexion"],
		action: CONNEXION
	},
	{
		match: ["creer-compte", "inscription"],
		action: CREATE_ACCOUNT
	},
	{
		match: ["mot-de-passe-oublie", "reset-password-for"],
		action: FORGOT_PASSWORD
	},
	{
		match: ["offrir-un-cinepass"],
		action: OFFER_CINEPASS
	},
	{
		match: ["offrir-un-abonnement"],
		action: OFFER_SUBSCRIPTION
	},
	{
		match: ["nos-offres", "ways-to-watch", "unsere-angebote"],
		action: OUR_OFFERS
	},
	{
		match: ["les-listes-de", "directors-lists", "die-listen-von"],
		action: LISTS
	},
	{
		match: ["desktop-player"],
		action: OFFLINE_PLAYER
	}
]

QueryMatcher.getInitialProps = async (ctx) => {
	let props = {}
	if (ctx.query) {
		let match = null
		Object.keys(ctx.query).forEach(key => {
			if (!match) {
				match = matches.find(m => m.match.includes(key))
				if (match) {
					match = { key, ...match }
				}
			}
		})
		if (match) {
			props.match = {
				action: match.action,
				value: ctx.query[match.key]
			}
		}
	}
	return props
}

export default QueryMatcher
